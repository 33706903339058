import * as React from 'react'
import WpApi from './wpapi'
import ObjectView from './ObjectView'

import './css/OrgDash.css'
import InputAddTimeversion from './InputAddTimeversion'
import FlexObjectRow from './FlexObjectRow'
import MyConf from './config'

interface MyProps {
    group_id:string
}
type MyState = {
    organisation:any
    new_timeversions:Array<any>
    relationtypes:any
}

class OrgDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)
	this.state = {
	    new_timeversions: [],
	    organisation:{},
	    relationtypes:{}
	}
    }

    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let path = '/group-content'
	let d = {
	    mode: 'get-content',
	    group_id: this.props.group_id
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    this.setState({
		organisation: r.org,
		relationtypes:r.relationtypes,
	    })
	});

    }
    open_popup(e:any, cb:(r:any)=>void){
        console.log(`open-popup cmd: ${e.cmd}`)
    }
    
    render_org(){
	let org = this.state.organisation
	let cfg = {
	    params: MyConf.params,
	    view : "org-dash"
	    
	}
	if(org.title === undefined){
	    return (<div>undefined</div>)
	}
	return (
	    <div className="org-item">
		<h4>{org.title}</h4>
		<ObjectView instance_id={org.id} config={cfg}/>
	    </div>
	)
    }
    render(){
	return (
	    <div className="org-dash">
		{this.render_org()}
	    </div>
	)
    }
}

export default OrgDash
