import * as React from 'react'
import WpApi from './wpapi'

interface MyProps {
    choices:any
    bb_group_id:number
    subgroup:any
    block:string
    object_id:number
    value:string
}
type MyState = {
    value:string
}
class GroupAdminPermSelect extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    value:''
	}
    }
    componentDidMount(){
	this.setState({
	    value:this.props.value
	})
    }

    on_change_permset(e:any, subgroup:string,object_id:number,block:string){
	let val = e.target.value
	let rrid = (block === undefined ? 'all' : block)

	console.log(` change ${object_id} ${block} to ${val}`)
	let path = '/group-adm'
	this.setState({value:val})

	let d = {
	    cmd: 'set-permset',
	    gid: this.props.bb_group_id,
	    subgroup:subgroup,
	    value: val,
	    object_id: object_id,
	    cft_block: rrid
	}

	WpApi.do_post(path, d, (r:any) => {
	    console.log(r)
	    if(r.status === 'ok'){
		console.log('ok public')
	    }
	})
	

    }
    render() {
	let subgroup = this.props.subgroup
	let block = this.props.block
	let object_id = this.props.object_id
	let curval = this.props.value

	let optlist = Object.entries(this.props.choices).filter((row:any) => {
	    if(subgroup === 'public' && row[0] == 'public'){return false}
	    return true
	})
	let sg_cls = ''
	return (
    	    <div className={"select "+sg_cls} >
		<select onChange={(e:any)=>this.on_change_permset(e,subgroup.id,object_id,block)} value={this.state.value}>
		{optlist.map(([sid,lab]:[string,any]) => <option key={sid} value={sid}>{lab}</option>)}
	    </select>
		</div>
	)
    }

}

export default GroupAdminPermSelect


