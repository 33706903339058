
import MyConf from './config'

interface HrefLinkProps {
  /** The text to display inside the button */
    page: "obj-dash" | "org-dash" | "vote"
    params:string
    text?:string

}


export default function HrefLink({page,params,text}: HrefLinkProps){

    let new_tab = (new MyConf).get().open_in_new_tab
    let deploymode = MyConf.params.deploymode
    console.log('deploymode '+deploymode)
    
    let url = "?" + params

    if(deploymode === "develop"){
	if(page == "obj-dash"){
	    url = "obj_dash.html?"+params
	}
	if(page == "org-dash"){
	    url = "org_dash.html?"+params
	}
	if(page == "vote"){
	    url = "vote_detail.html?" + params
	}
    }else{
	new_tab = true
	if(page == "obj-dash"){
	    url = "/cft-objects-dash?"+params
	}
	if(page == "org-dash"){
	    url = "/cft-org-dash?"+params
	}
	if(page == "vote"){
	    url = "/cft-vote?" + params
	}
    }
    let cls = "cft-link cft-small-icon "+deploymode
    let txt = (<span>text</span>)
    if( text === undefined){
	cls += " align-right"
	txt = (<img src={require("./img/open-link-icon-11.png")} />)
    }
    return new_tab ? (
	<a className={"blank-target "+cls} target="_blank" href={url}>{txt}</a>
    ) : (
	<a className={cls} href={url}>{txt}</a>
    )
}
