import * as React from 'react'
import WpApi from './wpapi'
import FeatureInputRow from './FeatureInputRow'
import RelationRow from './RelationRow'
import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from  './modals/HistoryModal'
import AddRelationModal from './modals/AddRelationModal'
import InputAddTimeversion from './InputAddTimeversion'
import FlexObjectRow from './FlexObjectRow'
import CftBlockCluster from './CftBlockCluster'
import Loco from './Loco'
interface cb_after_vote_event {
    (r:any): void;
}

interface MyProps {
    instance_id:string
    config:any
}
type MyState = {
    instance:any
    clusters:Array<any>
    cft_blocks:Array<any>
    corefeatures: Array<any>
    objectfeatures: Array<any>
    relations: Array<any>
    new_timeversions:Array<any>
}

class ObjectView extends React.PureComponent<MyProps,MyState> {

    private vote_popup =  React.createRef<VoteGridModal>();
    private history_popup =  React.createRef<HistoryModal>();
    
    private add_relation_popup =  React.createRef<AddRelationModal>();
    
    private fetchPromise ?: Promise<void>
	private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)


	
	this.state = {
	    corefeatures: [],
	    objectfeatures: [],
	    instance:null,
	    new_timeversions: [],
	    clusters:[],
	    cft_blocks:[],
	    relations: []
	}
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let path = '/features'
	let d : {[k:string]:string}= {
	    post_id: this.props.instance_id,
	    mode: 'get-options'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log(r)
	    this.setState({
		instance:r.instance,
		clusters: r.clusters,
		cft_blocks:r.cft_blocks,
		corefeatures: r.corefeatures,
		objectfeatures: r.objectfeatures,
		relations: r.relations
	    })
	})
    }
    get_user_vote(){
	
    }
    public open_popup(e:any, cb:(r:any)=>void){
        console.log(`open-popup cmd: ${e.cmd}`)
	let votetypes = [
            'choice-vote',
            'inputs-comment-vote',
            'comment-vote',
	    'relation-vote',
            'text-vote',
            'number-vote'
        ]
        if(votetypes.includes(e.cmd)){
            this.vote_popup.current!.init(e)
        }
	if(e.cmd == 'add-relation'){
	    this.add_relation_popup.current!.init(e)
	}
	else if(e.cmd == 'show-history'){
	    this.history_popup.current!.init(e)
	}
	
        // point callback to the calling Section
        this.callback_after_vote_event = cb;
    }
    after_vote_event(e:any){
	console.log('got-vote-event from popup')
	if(e.cmd === 'add_relation'){
	    let d = {...e,  attr_type: e.cmd}
	    WpApi.do_post('/block-attr',d, (r) =>{
		console.log(r)
		if(r.ret !== undefined && r.ret.status === "ok"){
		    window.location.reload()
		}
		if(r.error){
		    this.add_relation_popup.current!.set_warning(r.error)
		}

	    })
	}else if(e.kind === 'chosen_option'){
	    let d = {...e, attr_type: 'choice'}
	    WpApi.do_post('/block-attr',d, (r) => {
		console.log(r)
	    })
	}
	console.log(e)
    }

    render_corefeature(ftup:any){
	let pid = this.props.instance_id
	return (
	    <FeatureInputRow key={ftup.name} tup={ftup} post_id={Number(pid)}
	       cb_parent={this.open_popup}
		/>
	)

    }
    render_obj_feature(ftup:any){
	let pid = this.props.instance_id
	return (
	    <FeatureInputRow tup={ftup} key={ftup.key} post_id={Number(pid)}
	    	       cb_parent={this.open_popup}
		/>
	)
    }
    render_relation(tup:any){
	let pid = this.props.instance_id
	return (
	    <RelationRow instance={this.state.instance}
	    config={this.props.config}
	    key={tup.key} tup={tup} post_id={Number(pid)}
	       cb_parent={this.open_popup}
		/>
	)	
    }
    render_core_features(){
	if(this.state.instance.metatype !== "timeline"){return}
	let list = this.state.corefeatures
	if(list.length === 0) { return null }	
	return (
	    <div className="features core-features">
		<div className="body">
		{list.map((tup:any) => this.render_corefeature(tup))}
		</div>
	    </div>
	)
    }
    render_obj_features(){
	if(this.state.instance.metatype !== "timeline"){return}
	let list = this.state.objectfeatures
	if(list.length === 0) { return null }
	if(this.state.instance.metatype === "timeline"){
	    return
	}

	return (
	    <div className="features object-features">
		<div className="body">
		    {list.map((tup:any) => this.render_obj_feature(tup))}
		</div>
	    </div>
	)
    }
    render_obj_relations(){
	//if(this.state.instance !== "timeline"){return}
	let list = this.state.relations
	if(list.length === 0) { return null }
	if(this.state.instance.metatype === "timeline"){
	    return
	}


	return (
	    <div className="features relations">
		<h3> Object-relations </h3>
		<div className="body">
		    {list.map((tup:any) => this.render_relation(tup))}
		</div>
		</div>
	)
    }
    render_uom_for(){
	let tup = this.state.instance
	if(tup.obtype == 'uom'){
	    if(tup.for_relations !== undefined){
		let vals = tup.for_relations.join(", ")
		return (<div> Is built-in UOM for relations: {vals} </div>)
	    }
	}
	return null
    }
    render_instance(){
	let tup = this.state.instance
	let label = tup.title
	let supr = tup.object_type.cft_supertype
	let timeinfo = null
	if(tup.metatype==="timeperiod-version"){
	    timeinfo = (<div>Time-period: {tup.period } </div>)
	}
	else if(tup.metatype==="year-version"){
	    timeinfo = (<div>Year:  {tup.year} </div>)
	}
	return (
	    <div className="identity">
		<div>Object-type: {tup.object_type.label}</div>
		<div>Name: {label} </div>
	    {timeinfo}
	    {this.render_uom_for()}
	    </div>
	)
    }
    
    render_timeline_parent(){
	let tup = this.state.instance
	if(tup.parent === undefined){
	    return
	}
	return (
	    <div className="timeline-parent instances">
		Timeline (parent):

	    	<FlexObjectRow key={tup.parent.id} config={this.props.config}
	    context="timeversion"
	    tup={tup.parent} cb_parent={this.open_popup} />


	    </div>
	)
	
    }
    render_cluster(tup:any){
	let pid = this.props.instance_id

	return (
	    <CftBlockCluster key={tup.id}
	    context="objectview"
	    tup={tup} cft_blocks={this.state.cft_blocks}
	    post_id={Number(pid)}
	    instance={this.state.instance}
	    	    config={this.props.config}
	       cb_parent={this.open_popup}
		/>
	)
    }
    render_clusters(){
	let tup = this.state.instance

	return (
	    <div className="clusters features">
		{this.state.clusters.map((tup:any) => this.render_cluster(tup))}
	    </div>
	)
    }
    render(){
	let tup = this.state.instance
	if(tup === null){
	    return (
		<div> ERROR, object-instance {this.props.instance_id} was not found !</div>
	    )
	}



	//{this.render_instance()}
	return (

	    <div className="object-view">
		{this.render_timeline_parent()}
	    {this.render_clusters()}



	    	<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event} />
		<AddRelationModal ref={this.add_relation_popup} cb_parent={this.after_vote_event}  />
	    	<HistoryModal ref={this.history_popup}   />
	    </div>
	)
    }
}

export default ObjectView
