import * as React from 'react'
import WpApi from './wpapi'

import FlexObjectRow from './FlexObjectRow'

interface MyProps {
    tup:any
    post_id:number
    config:any
    instance:any
    cb_parent:   (e: any, cb: (r: any) => void) => void

}

type MyState = {

}
class RelationRow extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)
	this.on_click_add = this.on_click_add.bind(this)
	this.click_show_history = this.click_show_history.bind(this)
	this.click_show_votes = this.click_show_votes.bind(this)
    }
    componentDidMount(){
	let tup = this.props.tup

    }    
    on_click_add(){
	console.log('click')
	let lbl = 'Add relation: '+this.props.tup.label
	let d  = {
	    cmd: 'add-relation',
	    relation:this.props.tup,
	    instance:this.props.instance,
	    label: lbl
	}
	this.props.cb_parent(d, (r:any)=>{
	    console.log('callback')
	    console.log(r)
	})
    }
    expand_object(e:any,oid:string){
	console.log(` expand obj ${oid}`)
	e.target.closest('.instance').classList.add('expanded')
	// TODO: maak een soort van object-view hierbinnen
    }
    render_winner(tup:any){
	if(tup.related === undefined){
	    return null
	}
	let r = tup.related
	return (
	    <div key={r.id} className="winner-with-btns">
		<FlexObjectRow tup={r}
	    context="relation"
	    cb_parent={this.props.cb_parent}
	    config={this.props.config} />
		<div className="buttons">
		    <div className="show-votes cft-small-icon" onClick={this.click_show_votes}>
		      <img src={require("./img/icon-history.png")}/>
		    </div>
		</div>
	    </div>
	)
    }
    click_show_history(){
	console.log('show history')
	this.props.cb_parent({
	    cmd: 'show-history',
	    label: 'history',
	    post_id: this.props.post_id,
	    aspect:'current',
	    acf_key: this.props.tup.key
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
	    
    }
    click_show_votes(){
	console.log('show votes , claims + add claim')
	this.props.cb_parent({
	    label: 'Claims + add Claim',
	    cmd: 'relation-vote',
	    post_id: this.props.post_id,
	    aspect:'current',
	    acf_key: this.props.tup.key
	}, (e:any)=>{
	    console.log('return from cb-parent')
	})
	    
    }
    
    render_winners(){
	let tup = this.props.tup
	let list = tup.winners

	return (
	    <div className="winners instances">
		{list.map((x:any) => this.render_winner(x))}
		</div>
	)
    }
    render(){
	let tup = this.props.tup

	let label = tup.ui_label
	if(label === null || label === ""){
	    label = tup.label
	}
	return (
    	    <div key={tup.tup.rid} className="input-field relation">
		<div className="label">{label}</div>
		{this.render_winners()}
		<div className="cft-small-icon btn" onClick={this.on_click_add}>
		<img src={require('./img/plus-icon.png')} />
		</div>
	    </div>
	)
    }

}

export default RelationRow
