import * as React from 'react'
import WpApi from './wpapi'
import { ReactSortable } from "react-sortablejs";

interface MyProps {
    tup:any
    cft_blocks:Array<any>
}
type MyState = {
    label:string
    warning:string
    show_save_order:boolean
    cft_blocks:Array<any>
}
class UIAdminObjectCluster extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.save_ordered = this.save_ordered.bind(this)
	this.state = {
	    warning:'',
	    show_save_order:false,
	    cft_blocks:[],
	    label:''
	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(typeof tup.title === "string"){
	    this.setState({
		label: tup.title,

	    })
	}
	if(typeof tup.cft_blocks === "object"){
	    let blocks = tup.cft_blocks.map((block_id:string) => this.get_block_by_key(block_id))
	    this.setState({cft_blocks:blocks})
	}

    }
    save_ordered(){
	let list = this.state.cft_blocks.map((x:any) => x.key)
	let tup = this.props.tup
	console.log('save ordered')
	console.log(list)
	if(list.length==0){return}
	let d = {
	    block_keys:list,
	    cmd: 'save-blocks',
	    cluster_id:tup.id
	}
	console.log(d)
	let path = '/cft-blocks/admin'

	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    window.location.reload()
	})	
	
    }

    //private fetchPromise ?: Promise<void>
    render_cluster_blocks(tup:any){
	if(typeof this.state.cft_blocks !== "object"){
	    return (
		<div className="cft-blocks">-</div>
	    )
	}

	return (
	    <div>
	    <ReactSortable
		className="cft-blocks"
	    list={this.state.cft_blocks}
	    onUpdate={()=>{this.setState({show_save_order:true})}}
	    
		setList={(newState)=>this.setState({ cft_blocks: newState })}
	    >
		{this.state.cft_blocks.map((block:any) =>  (
		    <div className="cluster-block" key={block.key}>
			<div className="label">{block.label}</div>
		        <button onClick={()=>{this.remove_cft_block(tup.id,block.key)}}>remove from cluster</button>
		    </div>
		))}
	    
	    </ReactSortable>
		{this.state.show_save_order ? (
		    <button onClick={this.save_ordered}>save order</button>
		):null}
		
		</div>
	)
    }
    get_block_by_key(key:string){
	let list = this.props.cft_blocks
	let list0 = list.filter((x:any) => x.key === key)
	if(list0.length > 0){
	    let tup = list0[0]
	    return tup
	}
    }

    rename_block_cluster(tup:any){
	console.log('rename')
	let d = {
	    cmd: 'rename-cluster',
	    label : this.state.label,
	    cluster_id:tup.id,
	}
	console.log(d)
	let path = '/cft-blocks/admin'
	WpApi.do_post(path, d,(r) => {
	    if(r.error){
		this.setState({warning:r.error})
	    }else{
		window.location.reload()
	    }
	})
	
	    
    }
    remove_block_cluster(tup:any){
	let d = {
	    cmd: 'remove-block-cluster',
	    cluster_id: tup.id,
	}
	console.log(d)
	let path = '/cft-blocks/admin'
	WpApi.do_post(path, d,(r) => {
	    if(r.error){
		this.setState({warning:r.error})
	    }else{
		window.location.reload()
	    }
	})
    }
    remove_cft_block(tup_id:number,key:string){
	let d = {
	    cmd: 'remove-block-from-cluster',
	    cluster_id: tup_id,
	    cft_block_key : key
	}
	console.log(d)
	let path = '/cft-blocks/admin'
	WpApi.do_post(path, d,(r) => {
	    if(r.error){
		this.setState({warning:r.error})
	    }else{
		window.location.reload()
	    }
	})
    }
    render_warning(){
	if(this.state.warning == ''){
	    return
	}
	return (
	    <div className="warnings">
		{this.state.warning}
	    </div>
	)
    }
    
    render(){

	let tup = this.props.tup
	let isreadonly = false
	if(tup.built_in === "header" || tup.built_in === "timeversions"){
	    isreadonly = true
	}

	return (
	    <div className="row cluster-row" key={tup.id}>
		{isreadonly ? (
		    <div>{this.state.label}</div>
		):(
		<div className="label-with-btn">
		    <div className="input">
			<input value={this.state.label}
			       onChange={(e:any) => this.setState({label:e.target.value})}
			/></div>

		    <button onClick={(e:any)=>{this.rename_block_cluster(tup)}}>save</button>
		    <button onClick={(e:any)=>{this.remove_block_cluster(tup)}}>remove</button>

		</div>
		)}
		{this.render_cluster_blocks(tup)}
		{this.render_warning()}
	    </div>
	)
    }


}


export default UIAdminObjectCluster
