import * as React from 'react'
import WpApi from './wpapi'
import FeatureInputRow from './FeatureInputRow'
import RelationRow from './RelationRow'
import Expander_helper  from './Expander_helper'
import HrefLink from './HrefLink'
import FlexObjectRow from './FlexObjectRow'
import Loco from './Loco'
import InputAddTimeversion from './InputAddTimeversion'

interface MyProps {
    tup:any
    instance:any
    config:any
    context:string
    post_id:number
    cb_parent:   (e: any, cb: (r: any) => void) => void
    cft_blocks:Array<any>
}
type MyState = {
    new_timeversions:Array<any>
    label:string
    warning:string
}
class CftBlockCluster  extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    new_timeversions:[],
	    warning:'',
	    label:''
	}
    }
    componentDidMount(){
	let tup = this.props.tup
	if(typeof tup.title === "string"){
	    this.setState({ label: tup.title})
	}
    }
    get_block_by_key(key:string){
	let list = this.props.cft_blocks
	let list0 = list.filter((x:any) => x.key === key)
	if(list0.length > 0){
	    return list0[0]
	}
	return null
    }
    render_corefeature(ftup:any){
	let pid = this.props.post_id
	return (
	    <FeatureInputRow key={ftup.name} tup={ftup} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)

    }
    render_obj_feature(ftup:any){
	let pid = this.props.post_id
	return (
	    <FeatureInputRow tup={ftup} key={ftup.key} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)
    }
    render_relation(tup:any){
	let pid = this.props.post_id
	return (
	    <RelationRow instance={this.props.instance}
	    config={this.props.config}
	    key={tup.key} tup={tup} post_id={Number(pid)}
	    cb_parent={this.props.cb_parent}
		/>
	)	
    }

    render_cft_block(block_id:string){
	let tup = this.get_block_by_key(block_id)
	if(tup === null){return }
	let row = null
	if(tup.block_type === "corefeature"){
	    row = this.render_corefeature(tup)
	}else if(tup.block_type === "objectfeature"){
	    row = this.render_obj_feature(tup)
	}else if(tup.block_type === "relation"){
	    row = this.render_relation(tup)
	}
	return (
	    <div className="cft-block" key={block_id}>
	    {row}
	    </div>
	)
    }
    render_cft_blocks(){
	let tup = this.props.tup
	if(tup.built_in === "timeversions"){
	    return this.render_timeversions()
	}
	if(tup.cft_blocks === undefined){return}
	if(typeof tup.cft_blocks !== "object") {return}
	return (
	    <div>
		{tup.cft_blocks.map((block_id:string) => this.render_cft_block(block_id))}
	    </div>
	)
    }
    render_timeversion_txt(inst:any){
	if(inst.metatype === "year-version"){
	    return (<div>Year:{inst.year}</div>)
	}
	if(inst.metatype === "timeperiod-version"){
	    return (<div>Time-period: {inst.period} </div>)
	}
	
    }
    render_hyperlink(){
	let inst = this.props.instance
	let params = 'obtype='+inst.obtype+'&instance='+inst.id

	if(this.props.context === "relation"){
	    return (
		<HrefLink page="obj-dash" params={params}  />
	    )
	}
	return null
    }
    render_header_txt(){
	let inst = this.props.instance
	let postfix = ""


	
	if(inst.metatype === "year-version"){
	    postfix = "("+inst.year+")"
	}
	else if(inst.metatype === "timeperiod-version"){
	    postfix = "("+inst.period+")"
	}
	
	//
	return (
	    <div>
		{this.render_hyperlink()}
		<div> {inst.object_type.label}: {inst.title} {postfix}</div>
	    </div>
	)
    }
    render_new_timeversions(tup_id:number){
	let list = this.state.new_timeversions
	return (
	    <div className="new-timeversions">
		{list.map((tv:any) => this.render_timeversion(tv))}
	    </div>
	)
    }
    render_timeversion(tup:any){
	let mode = undefined
	//
	if(this.props.instance.metatype === "timeline"){
	    mode="timeversion-as-label"
	}
	return (
	    <FlexObjectRow key={tup.id} config={this.props.config}
	    context="timeversion"
	    tup={tup} cb_parent={this.props.cb_parent} mode={mode} />
	)
    }
    render_timeversions(){

	let tup = this.props.instance
	let list = tup.timeversions
	if(list === undefined){
	    return
	}
	let placeholder = ""
	let ot = tup.object_type
	let tt = ot.cft_timetype
	if(tt === "year"){
	    placeholder = "- type a year version to add"
	}
	else if(tt === "timeperiod"){
	    placeholder = "- type a timeperiod version to add (YYYY-YYYY) "
	}
	
	return (
	    <div className="timeversions instances">
		{Object.entries(list).map(([k,x]:[string,any])=> this.render_timeversion(x))}
		{this.render_new_timeversions(tup.id)}		
	        <InputAddTimeversion
		    tup={this.props.instance}
	            cb={(tv:any) => {
			let prev = this.state.new_timeversions
			this.setState({new_timeversions: [...prev, tv]})
		    }}
		/>
	    </div>
	)
	
    }
    render(){
	let tup = this.props.tup
	let exp = new Expander_helper()

	let cls = "cluster with-expand"
	if(tup.title !== "header"){
	    cls += " collapsed"
	}

	if(tup.built_in === "header"){
	    return (
		<div className="cluster header">
		    <div className="body">
			{this.render_header_txt()}
			{this.render_cft_blocks()}
		    </div>
		</div>
	    )
	}

	let title = tup.title
	if(tup.built_in === "timeversions"){
	    title = Loco.tr('Year by year')
	}

	return (
	    <div className={cls}>
		<div className="header" onClick={exp.toggle_expand}>
		  <div className="pre-icon"></div>
		  <div className="label">{title} </div>
	        </div>

		<div className="body">
		{this.render_cft_blocks()}
		</div>
	    </div>
	    
	)
	//
    }
}
export default CftBlockCluster 
