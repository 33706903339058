import * as React from 'react'
import WpApi from './wpapi'
import UIAdminObjectType from './UIAdminObjectType'
interface MyProps {

}
type MyState = {
    object_types:any
    object_type:string
    for_timeline:boolean
}
class UIAdminDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>


    constructor(props: MyProps) {
        super(props)
	
	this.on_chosen_otype = this.on_chosen_otype.bind(this)
	
	this.state = {
	    object_type:'',
	    for_timeline:false,
	    object_types: null
	}
    }
    componentDidMount(){
	window.addEventListener("popstate", function (e) {
	    // enforce loading the new href
	    let url = window.location.href
	    window.location.href = url
	    e.preventDefault();
	});
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	let path = '/objects'
	let d : {[k:string]:string}= {
	    mode: 'get-options'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState( { object_types: r.options},
			   this.set_state_objtype_from_url)
	})
    }
    set_state_objtype_from_url(){
	let p = new URLSearchParams(window.location.search)
	let ot = p.get('obtype')
	let tl = p.get('timeline')
	let timeline = tl === null ? false : true

	if(ot !== null){
	    this.setState( {
		object_type:  ot,
		for_timeline: timeline
	    })
	}
    }

    on_chosen_otype(ot:string){
	console.log(`chosen otype ${ot}`)
	const url = new URL(window.location.href)
	url.searchParams.set("obtype",ot)
	window.history.pushState({obtype:ot},"",url)
	this.setState({ object_type: ot})
    }
    
    render_listitem(k:string,tup:any){
	return (
	    <div key={k} onClick={()=>{this.on_chosen_otype(k)}}>  {tup.label}</div>
	)
    }
    render_list(){
	if(this.state.object_type != ''){return null}
	if(this.state.object_types === null){
	    return
	}
	let items = Object.entries(this.state.object_types)
	return (<div> 
		{items.map(([k,tup]:[string,any]) => this.render_listitem(k,tup))}
		</div>)
    }
    render_objtype(){
	let k = this.state.object_type
	if(k == ''){return null}
	let tup = this.state.object_types[k]
	return (
	    <div> chosen: {k}
		<UIAdminObjectType tup={tup} for_timeline={this.state.for_timeline} />
	    </div>
	)
    }
    render(){
	return (
	    <div>
		{this.render_list()}
	    {this.render_objtype()}
		</div>
	)
    }
}

export default UIAdminDash
