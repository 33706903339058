import * as React from 'react';
import InstancesList from '../InstancesList'

interface MyProps {
    relation:any
    instance:any
    cb_parent:(e?: any) => void
}

type MyState = {
    number_input:string
    uom:string

}

class AddRelationForm extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.on_chosen_instance = this.on_chosen_instance.bind(this)
	this.change_number_input = this.change_number_input.bind(this)
	this.state = {
	    uom:"",
	    number_input:""
	}
    }
    
    
    on_chosen_instance(e:any){
	console.log('chosen')
	console.log(e)
	let rel = this.props.relation
	let inst = this.props.instance
	let d = {
	    cmd: 'add_relation',
	    cft_block: rel.tup.rid,
	    inverse: rel.tup.inverse,
	    itema_name: rel.tup.itema_name,
	    itemb_name: rel.tup.itemb_name,
	    target_1: inst.id,
	    uom: this.state.uom,
	    block_type: 'relation',
	    aspect: 'current',
	    factor: this.state.number_input,
	    target_2: e.id
	}
	console.log(d)
	this.props.cb_parent(d)
    }
    render_uom_selector(ilist:Array<any>){
	if(ilist.length == 0){return}
	return (
	    <div>
		<select onChange={(e:any) => {this.setState({uom:e.target.value})}}>
	         <option value="">- kies UOM -</option>
		{ilist.map((tup:any) => ( <option key={tup.id} value={tup.id}> { tup.title } </option>))}
		</select>
	    </div>
	)
    }
    change_number_input(e:any){
	let v = e.target.value
	const re = /^\d+$/;
	if(! re.test(v)){
	    return
	}

	this.setState({number_input: v})
    }
    render_number_input(){
	let rel = this.props.relation
	let label = rel.tup.cft_rel_factor
	if(label=== ""){
	    return null
	}
	return (
	    <div className="number-input">
		{label}
	         {this.render_uom_selector(rel.uoms)}

		<input
		    value={this.state.number_input}
		    placeholder="-type a number-"
		    onChange={this.change_number_input}
		/>
	    </div>
	)
    }
    render(){
	let rel = this.props.relation
	let inst = this.props.instance
	let inverse = rel.tup.inverse
	let target_type = inverse ? rel.tup.itema : rel.tup.itemb

	return (
	    <div className="add-relation-form">
		<InstancesList
	    showheader={false}
		    mode={"timeversion"}
		    object_type={target_type}
		    cb={this.on_chosen_instance}
		/>
		{this.render_number_input()}

	     
	    </div>
	)
    }
}


export default AddRelationForm
